import { Api } from '@/libraries/api'

async function getTopics() {
  const url = `/admin/push/sns/topics`
  const apiResult = await Api().get(url)
  const { items } = apiResult.data.data
  return items
}

async function getCarerArn(carerIdx) {
  const url = `/admin/push/caregiver/${carerIdx}/address`
  const apiResult = await Api().get(url)
  const { item } = apiResult.data.data

  if (item === null) {
    return ''
  }
  return item.sns_address
}

async function submit(formData) {
  const url = `/admin/push/`
  const apiResult = await Api().post(url, formData)
  const { result } = apiResult.data
  return result
}

export default {
  getTopics,
  getCarerArn,
  submit
}

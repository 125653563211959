<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <PageTitleComponent title="Notification 발송" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-card :loading="isLoading">
            <v-card-title>
              발송 대상 선택
            </v-card-title>
            <v-card-text>
              <v-list class="my-4">
                <v-radio-group
                  v-model="sendType"
                  hide-details="auto"
                  row
                  class="mt-0"
                >
                  <v-radio
                    label="그룹"
                    value="group"
                    on-icon="radio_button_checked"
                    off-icon="radio_button_unchecked"
                  />
                  <v-radio
                    label="개인"
                    value="individual"
                    on-icon="radio_button_checked"
                    off-icon="radio_button_unchecked"
                  />
                </v-radio-group>
              </v-list>
              <v-list
                v-show="sendType === 'group'"
                class="my-4"
              >
                <v-select
                  ref="form__select_group"
                  v-model="formData.sns_address"
                  :items="topics"
                  label="토픽 목록"
                  item-text="desc"
                  item-value="arn"
                  append-icon="expand_more"
                  dense
                  outlined
                  hide-details="auto"
                />
              </v-list>
              <v-list
                v-show="sendType === 'individual'"
                class="my-4"
              >
                <CarerSelectDialogComponent
                  ref="carer_select_dialog_component"
                  @closeDialog="onCloseDialog"
                />
                <p v-show="selectedUserMoreThanOnce">
                  <span class="text-subtitle-1 font-weight-bold pr-1">{{ selectedUser.basic_info.name }}</span>
                  <v-chip
                    label
                    small
                  >
                    ({{ selectedUser.basic_info.age }}, {{ selectedUser.basic_info.getGender() }})
                  </v-chip>
                  님 선택됨
                </p>
                <v-btn
                  ref="from__select_user_button"
                  color="primary"
                  @click="$refs.carer_select_dialog_component.open()"
                >
                  간병인 {{ selectedUserMoreThanOnce ? '재': '' }}선택
                </v-btn>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-card :loading="isOnSubmit">
            <v-card-title>
              발송 메세지 작성
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-text-field
                  ref="form__title"
                  v-model="formData.title"
                  max-length="20"
                  counter="20"
                  label="제목"
                  outlined
                  :rules="[rules.required, rules.title]"
                />
              </v-list>
              <v-list>
                <v-textarea
                  ref="form__body"
                  v-model="formData.body"
                  counter="80"
                  label="내용"
                  outlined
                  :rules="[rules.required, rules.body]"
                />
              </v-list>
              <v-list class="text-center">
                <v-btn
                  color="primary"
                  @click="submit"
                >
                  전송
                </v-btn>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Api from './models/api'
import PageTitleComponent from '../_elements/title/PageTitle'
import CarerSelectDialogComponent from '../carer/ItemsSelectDialog'
import { CarerSchema } from '../carer/model/carerSchema'

export default {
  components: {
    PageTitleComponent,
    CarerSelectDialogComponent
  },
  data() {
    return {
      sendType: 'group',
      topics: [],
      isLoading: false,
      isOnSubmit: false,
      openSelectUserDialog: false,
      selectedUserMoreThanOnce: false,
      valid: false,
      lazy: true,
      rules: {
        required: (v) => !!v || '꼭 입력해주세요',
        title: (v) => (v && v.length >= 1 && v.length <= 20) || '제목은 20자 이하로 입력해주세요',
        body: (v) => (v && v.length >= 1 && v.length <= 80) || '내용은 80자 이하로 입력해주세요'
      },
      selectedUser: new CarerSchema(),
      formData: {
        sns_address: '',
        title: '',
        body: ''
      }
    }
  },
  async created() {
    this.isLoading = true
    this.topics = await Api.getTopics()
    this.isLoading = false
  },
  methods: {
    reset() {
      this.sendType = 'group'
      this.valid = false
      this.lazy = true
      this.openSelectUserDialog = false
      this.selectedUserMoreThanOnce = false
      this.selectedUser = new CarerSchema()
      this.formData = {
        sns_address: '',
        title: '',
        body: ''
      }
    },
    async onCloseDialog(carerItem) {
      this.isLoading = true
      this.selectedUser = carerItem
      this.openSelectUserDialog = false
      const arn = await Api.getCarerArn(carerItem.extra_info.idx)
      if (arn === '') {
        this.$bus.$emit('errorMessage', '해당 유저의 notification 주소가 등록되지 않았습니다. (알람설정 off 일 수 있습니다)')
        this.selectedUserMoreThanOnce = false
        this.selectedUser = new CarerSchema()
        this.$refs.carer_select_dialog_component.open()
      } else {
        this.selectedUserMoreThanOnce = true
        this.formData.sns_address = arn
      }
      this.isLoading = false
    },
    async submit() {
      this.isOnSubmit = true
      if (this.sendType === 'group' && this.formData.sns_address === '') {
        this.$bus.$emit('errorMessage', '토픽 목록을 선택해주세요')
        this.$refs.form__select_group.focus()
      } else if (this.sendType === 'individual' && this.formData.sns_address === '') {
        this.$bus.$emit('errorMessage', '간병인을 선택해주세요')
        this.$refs.from__select_user_button.$el.focus()
      } else if (this.$refs.form.validate() === false) {
        this.$bus.$emit('errorMessage', '폼을 규칙에 맞게 입력해주세요')
        this.$refs.form__title.focus()
      } else {
        const result = await Api.submit(this.formData)
        if (result === true) {
          this.$bus.$emit('successMessage', '전송 요청에 성공하였습니다.')
          this.reset()
        } else {
          this.$bus.$emit('errorMessage', '전송 요청에 실패하였습니다.')
        }
      }
      this.isOnSubmit = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

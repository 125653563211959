/* eslint-disable max-classes-per-file */
// import { Api } from '@/libraries/api'
import { getConvertedAddressText } from '../../address/model/addressFinder'
import Api from './api'

/*
carer input form schema
{
  "phone_number": "01012345678",
  "name": "홍길동",
  "age": 62,
  "gender": "male",
  "license": "요양 보호사",
  "career": 10,
  "desired_salary": "1",
  "office_hour": "anytime",
  "introduce": "안녕하세요 열심히 하겠습니다",
  "profile_image": "https://image.s3.bucket.com",
  "working_area_idx": 1,
  "working_area_detail_idx": 1,
  "marketing_notification": 1,
  "event_notification": 1,
}
*/

/*
"basic_info": {
  "idx": 0,
  "email": "example@example.com",
  "phone": "01012345678",
  "password": "supersecret",
  "name": "김아무개",
  "age": 3500,
  "gender": "male"
},
"extra_info": {
  "idx": 1,
  "license": "요양 보호사",
  "career": 10,
  "desired_salary": "1",
  "office_hour": "anytime",
  "introduce": "안녕하세요 열심히 하겠습니다",
  "profile_image": "https://image.s3.bucket.com",
  "working_area_idx": 1,
  "working_area_detail_idx": 1,
  "marketing_notification": 1,
  "event_notification": 1,
  "created_at": "2021-05-01 14:23:00",
  "updated_at": "2021-05-01 14:23:00"
},
"work_info": {
  "number_of_completions": 100
}
*/
const CarerBasicInfoSchema = class {
  constructor(item = {}) {
    this.idx = item.idx || 0
    this.email = item.email || null
    this.phone = item.phone || null
    this.password = item.password || null
    this.name = item.name || null
    this.age = item.age || 0
    this.gender = item.gender || null
  }

  getGender() {
    let gender = 'Unknown'
    if (this.gender === 'male') {
      gender = '남'
    } else if (this.gender === 'female') {
      gender = '여'
    }
    return gender
  }
}

const CarerExtraInfoSchema = class {
  constructor(item = {}) {
    this.idx = item.idx || 0
    this.license = item.license || ''
    this.career = item.career || 0
    this.desired_salary = item.desired_salary || ''
    this.office_hour = item.office_hour || ''
    this.introduce = item.introduce || ''
    this.profile_image = item.profile_image || ''
    this.working_area_idx = item.working_area_idx || 0
    this.working_area_detail_idx = item.working_area_detail_idx || 0
    this.marketing_notification = item.marketing_notification || 0
    this.event_notification = item.event_notification || 0
    this.created_at = item.created_at || ''
    this.updated_at = item.updated_at || ''
  }

  async getAddress() {
    const address = await getConvertedAddressText(
      this.working_area_idx,
      this.working_area_detail_idx
    )
    return address
  }

  async isPaid() {
    const result = await Api.getCheckPaid(this.idx)
    return result ? 'Y' : 'N'
  }
}

const CarerWorkInfoSchema = class {
  constructor(item = {}) {
    this.number_of_completions = item.number_of_completions || 0
  }
}

const CarerMatchInfoSchema = class {
  constructor(item = {}) {
    this.created_at = item.created_at || '1970-01-01T00:00:00'
    this.updated_at = item.updated_at || '1970-01-01T00:00:00'
  }
}

export const CarerSchema = class {
  constructor(item = {}) {
    this.basic_info = new CarerBasicInfoSchema(item.basic_info || {})
    this.extra_info = new CarerExtraInfoSchema(item.extra_info || {})
    this.work_info = new CarerWorkInfoSchema(item.work_iinfo || {})
    this.match_info = new CarerMatchInfoSchema(item.match_info || {})
  }
}

export const CarerFormSchema = class {
  constructor(item = {}) {
    this.phone_number = item.phone_number || ''
    this.name = item.name || ''
    this.age = item.age || 0
    this.gender = item.gender || 'female'
    this.license = item.license || ''
    this.career = item.career || 0
    this.desired_salary = item.desired_salary || ''
    this.office_hour = item.office_hour || ''
    this.introduce = item.introduce || ''
    this.profile_image = item.profile_image || ''
    this.working_area_idx = item.working_area_idx || 0
    this.working_area_detail_idx = item.working_area_detail_idx || 0
    this.marketing_notification = item.marketing_notification || 0
    this.event_notification = item.event_notification || 0
  }
}

/* eslint-disable max-classes-per-file */
import { Api, makeGetUrl } from '@/libraries/api'
import { CarerSchema } from './carerSchema'
import paginationLib from '../../_elements/pagination/paginationLib'

const CarerList = class {
  constructor() {
    // this.pagination = new Pagination()
    this.pagination = {}
  }

  async getList(filterName) {
    const url = `/caregiver`
    const params = {
      page: this.pagination.page,
      limit: this.pagination.limit,
      filters: filterName ? encodeURIComponent(`name=${filterName}`) : null
    }
    const apiResult = await Api().get(makeGetUrl(url, params))
    const { items, pagination } = apiResult.data.data
    return {
      items: items.map((item) => new CarerSchema(item)),
      pagination: paginationLib.paginationMapper(pagination)
    }
  }
}

export default CarerList

<template>
  <v-dialog
    v-model="isDialogOpen"
    width="100%"
  >
    <v-card>
      <v-card-title>간병인 목록</v-card-title>

      <v-container>
        <v-row>
          <v-col cols="12">
            <v-simple-table
              class="tbl elevation-2"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="item in headers"
                      :key="item.text"
                      :class="`text-${item.align}`"
                    >
                      {{ item.text }}
                    </th>
                  </tr>
                </thead>
                <TbodyPreRender
                  :is-loading="isLoading"
                  :cols="headers.length"
                  :items-length="items.length"
                />
                <tbody v-if="!isLoading">
                  <tr
                    v-for="item in items"
                    :key="`patient-item-${item.extra_info.idx}`"
                  >
                    <td class="text-center">
                      <v-btn
                        small
                        color="primary"
                        @click="pickItem(item)"
                      >
                        선택
                      </v-btn>
                    </td>
                    <td class="py-2">
                      <v-chip
                        small
                        outlined
                        class="mr-2 ml-2 justify-center"
                        :color="(item.basic_info.gender === 'male') ? 'blue' : 'red'"
                      >
                        {{ item.basic_info.age }}세, {{ item.basic_info.getGender() }}
                      </v-chip>
                      <!-- <router-link :to="`/carers/${item.extra_info.idx}`"> -->
                      {{ item.basic_info.name }}
                      <!-- </router-link> -->
                    </td>
                    <td class="text-center pr-2">
                      {{ $options.filters.numberFormat((item.work_info.number_of_completions || 0)) }}
                    </td>
                    <td class="text-center">
                      {{ item.extra_info.career }}
                    </td>
                    <td class="text-center">
                      {{ item.extra_info.license || 'N/A' }}
                    </td>
                    <td class="py-2">
                      <v-chip
                        outlined
                        small
                      >
                        <Promised
                          :promise="item.extra_info.getAddress()"
                        >
                          <template v-slot:combined="{ data }">
                            {{ data }}
                          </template>
                        </Promised>
                      </v-chip>
                    </td>
                    <td class="py-2">
                      <v-chip
                        label
                        small
                        class="mr-2"
                      >
                        {{ item.extra_info.office_hour }}
                      </v-chip>
                      <v-chip
                        label
                        small
                        class="mr-2"
                      >
                        {{ item.extra_info.desired_salary }}
                      </v-chip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-col cols="12">
            <!-- <PaginationComponent v-model="model.pagination" /> -->
            <v-pagination
              v-model="model.pagination.page"
              class="pagination-circle"
              circle
              :length="model.pagination.length"
              :total-visible="model.pagination.totalVisible"
              prev-icon="keyboard_arrow_left"
              next-icon="keyboard_arrow_right"
              @input="getItems(model.pagination.page)"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
// import paginationLib from '../_elements/pagination/paginationLib'
// import PaginationComponent from '../_elements/pagination/Pagination'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import CarerList from './model/carerList'
// import Api from './model/api'

export default {
  components: {
    // PaginationComponent,
    TbodyPreRender
  },
  data() {
    return {
      model: new CarerList(),
      isDialogOpen: false,
      isLoading: false,
      query: '',
      items: [],
      headers: [
        { text: '선택', align: 'center' },
        { text: '프로필', align: 'center' },
        { text: '매칭횟수', align: 'center' },
        { text: '경력', align: 'center' },
        { text: '자격증', align: 'center' },
        { text: '활동지역', align: 'center' },
        { text: '희망조건', align: 'center' }
      ]
    }
  },
  created() {
    this.getItems()
    this.isDialogOpen = this.dialogOpen
  },
  methods: {
    async getItems() {
      this.isLoading = true
      const { items, pagination } = await this.model.getList()
      if (items) {
        this.items = items
        this.model.pagination = { ...pagination } // vue 2 not catch deep object
      }
      this.isLoading = false
    },
    open() {
      this.isDialogOpen = true
    },
    pickItem(item) {
      this.isDialogOpen = false
      this.$emit('closeDialog', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  ::v-deep table {
    min-width: 500px;
  }
}
.theme--light.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>

import { Api } from '@/libraries/api'

async function deletePass(idx) {
  const url = `/admin/pass/${idx}`
  const apiResult = await Api().delete(url)
  const { result } = apiResult.data
  return result
}

async function getCheckPaid(idx) {
  const url = `/admin/pass/caregiver/${idx}`
  const apiResult = await Api().get(url)
  const { result } = apiResult.data
  return result
}

export default {
  deletePass,
  getCheckPaid
}
